import Triennium from '@/helpers/triennium';
import { sortByProperty } from '@/helpers/sort';

function splitIntoConsecutiveGroups(items) {
  return items.reduce((acc, curr, index, array) => {
    // Check if it's the first item or if the current number is not consecutive
    if (index === 0 || curr.triennium.number !== array[index - 1].triennium.number + 1) {
      // Start a new sub-array for non-consecutive numbers
      acc.push([curr]);
    } else {
      // Add to the last sub-array for consecutive numbers
      acc[acc.length - 1].push(curr);
    }
    return acc;
  }, []);
}

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Years',
      flex: 1,
      autoHeight: true,
      sortIndex: 0,
      sortable: false,
      sort: 'desc',
      valueGetter: (params) => params.data,
      cellRenderer: (params) => {
        const { positions } = params.data;

        // Sort Ascending
        positions.sort((a, b) => a.triennium.number - b.triennium.number);

        const allGroups = splitIntoConsecutiveGroups(positions);

        const years = [];
        allGroups.forEach((positions) => {
          const firstTriennium = positions[0].triennium;
          const endTriennium = positions[positions.length - 1].triennium;
          years.push({
            from: firstTriennium.from,
            to: endTriennium.to,
          });
        });

        years.sort((a, b) => b.from - a.from);

        const currentYear = new Date().getFullYear();
        const currentTriennium = new Triennium(Triennium.calculateNumberFromYear(currentYear));

        return years
          .map((y) => {
            if (currentTriennium.to() === y.to) {
              return `<div>${y.from} - current</div>`;
            }

            return `<div>${y.from} - ${y.to}</div>`;
          })
          .join('');
      },
      comparator(valueA, valueB) {
        if (valueA.is_deceased === valueB.is_deceased) {
          return 0;
        }
        return valueA.is_deceased ? -1 : 1;
      },
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Full Name',
      flex: 1,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      sort: 'asc',
      sortIndex: 1,
      sortable: false,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}

export function gridRowStyle(params) {
  const allRowData = [];
  params.api.forEachNodeAfterFilterAndSort((node) => {
    allRowData.push(node);
  });

  // Get First Deceased
  const scNode = allRowData.find((node) => node.data.is_deceased);

  if (scNode) {
    if (scNode.rowIndex === params.node.rowIndex) {
      return {
        borderTop: '2px solid black',
      };
    }
  }

  return undefined;
}

export function handleAffiliates(affiliates) {
  return affiliates;
}
